import React from "react";
// reactstrap components
import { Row, Container, Col } from "reactstrap";
import Image from "react-bootstrap/Image";
import { Localhost_Domain } from "../../constant";
import { useLocation } from 'react-router-dom';

import FooterOrderNow from "./Footer-order-now";
import NewsletterModal from "../Pages/NewsletterModal";

function Footer() {
  // Parent Component
  const location = useLocation(); // React Hook

  //To get path
  let checkRoute = location.pathname;
  
  return (
    <>     
   {checkRoute !== "/newsletter" && checkRoute !== "/where-to-buy" && checkRoute !== "/where-to-buy/find-near-me/" && checkRoute !== "/where-to-buy/shop-online" ? (<FooterOrderNow />):null}
    <footer className="text-white sLwbX footerText">
      <Container>      
        <Row>
          <Col lg={3} md={12} className='footerLogo'>
            <Image src={`${Localhost_Domain}/img/Old-Parr-Footer-Logo.png`} />
          </Col>
          <Col lg={9} md={12}>
            <div id="footer"></div>
          </Col>
        </Row>
      </Container>
      <NewsletterModal />
    </footer>
    </>
  );
}

export default Footer;