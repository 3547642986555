import React, { useEffect, useState } from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import { Localhost_Domain } from "../../constant";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";

export default function OurWhiskies18() {
  //set initial values empty to array
  const [data, setData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  //function for set data to variable
  const getData = () => {
    fetch("whiskies.json", {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        setData(myJson);
      });
  };

  //Funcation call onece for data loading
  useEffect(() => {
    getData();
  }, []);

  let urlSlug = "old-parr-18-yo"; //`${params.slug}`;
  //filter by slug
  const whisky = data.whiskies;
  const whisky_result = whisky
    ? whisky.find(({ slug }) => slug === urlSlug)
    : [];

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Grand Old Parr Scotch</title>
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
        >
          <section className="bottel-container">
            <div className="bottel-bg">
              <Image
                src={`${Localhost_Domain}/img/whisky/12yr-page-banner.png`}
                className="img-fluid"
              />
            </div>
            <div className="bottel-img">
              <Image
                src={`${Localhost_Domain}/img/whisky/${whisky_result.bottleImage}`}
                className="img-fluid deksTopImg"
              />
              <Image
                src={`${Localhost_Domain}/img/whisky/bottle-straight.png`}
                className="img-fluid mobileImg"
              />
            </div>
            <Container>
              <Row className="brand-img-div">
              <Col md={6} xs={0} className='colNone'></Col>
                <Col md={6} xs={12}>
                  <div className="brandAge">
                    <h3>AGED</h3>
                    <div className="age-img">
                      <h1>OLD PARR</h1>
                      <div>
                        <Image
                          src={`${Localhost_Domain}/img/whisky${whisky_result.ageImage}`}
                          className="img-fluid"
                        />
                      </div>
                    <Link to="/the-serves" className="forDekstop">
                        <Button className="btn-danger">See our serves</Button>
                      </Link>
                    </div>
                    <h3>YEARS</h3>
                  </div>

                <div className="buttons mt-lg-5 mt-5 forMobile mx-auto">
                    <Link to="/the-serves">
                    <Button className="outline-btn-danger serveBtn">
                        See our serves
                      </Button>
                    </Link>
                    <Link to="/where-to-buy">
                      <Button className="bg-btn-danger">WHERE TO BUY</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container style={{maxWidth: '755px'}}>
              <Row className="awards mt-5">
                <Col md={12} sm={12} className='text-center pb-3'>
                  <h1><span className="text-danger">A</span>WARDS</h1>
                </Col>
                <Col md={4} sm={12}>
                  <div className="age-img">
                    <div className="text-center">
                      <Image
                        src={`${Localhost_Domain}/img/coins${whisky_result.RedDoubleGoldMed}`}
                        className="img-fluid"
                      />
                    </div>
                    <h4 className="mt-3">96 SCORE, 2022 ULTIMATE <br /> SPIRITS CHALLENGE</h4>
                    <p className="text-center">
                    Extraordinary, Ultimate <br/> Recommendation, Great Value
                    </p>
                  </div>
                </Col>

                <Col  md={4} sm={12}>
                  <div className="age-img">
                    <div className="text-center">
                      <Image
                        src={`${Localhost_Domain}/img/coins/${whisky_result.DoubleGoldMedOld}`}
                        className="img-fluid"
                      />
                    </div>
                    <h4 className="mt-3">GOLD <br/> 2022</h4>
                    <p className="text-center">
                    San Francisco<br/>World Spirits Competition
                    </p>
                  </div>
                </Col>

                <Col  md={4} sm={12}>
                  <div className="age-img">
                    <div className="text-center">
                      <Image
                        src={`${Localhost_Domain}/img/coins${whisky_result.DoubleGoldMed}`}
                        className="img-fluid"
                      />
                    </div>
                    <h4 className="mt-3">DOUBLE GOLD <br/> 2024</h4>
                    <p className="text-center">
                    OP 18 won a Gold <br/> at the 2024 SF Awards
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="imgAndDesc container">
              <Row>
                <Col lg={7} md={6} xs={12}>
                  <div className="wbottle text-center yr18-bottle-img">
                    <Image
                      src={`${Localhost_Domain}/img/whisky${whisky_result.descriptionImage}`}
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col
                  lg={5}
                  md={6}
                  xs={12}
                  className="d-flex align-items-center imgDescTextDiv"
                >
                  <div className="descMainDiv eighteenyr">
                    <h1>{whisky_result.descriptionTitle}</h1>
                    <p className="mt-3">{whisky_result.descriptionText}</p>
                    <Row className="ps-lg-4 mt-lg-4 mt-4 d-md-flex d-none">
                      <Col xs={2}>
                        <div className="headingText">
                          <p>Color</p>
                          <p>Nose</p>
                          <p>Taste</p>
                          <p>Finish</p>
                        </div>
                      </Col>
                      <Col xs={1} className="p-0 borderCOl"></Col>
                      <Col xs={9}>
                        <div className="descText desc_content">
                          <p>{whisky_result.descriptionColor}</p>
                          <p>{whisky_result.descriptionNose}</p>
                          <p>{whisky_result.descriptionTaste}</p>
                          <p>{whisky_result.descriptionFinish}</p>
                        </div>
                      </Col>
                    </Row>
                    <div className="buttons mt-lg-4 mt-4">
                      <Link to="/the-serves">
                        <Button className="outline-btn-danger">
                          See our serves
                        </Button>
                      </Link>
                      <Link to="/where-to-buy">
                        <Button className="bg-btn-danger">WHERE TO BUY</Button>
                      </Link>
                    </div>

                    <div className="percent-text mt-lg-5 mt-4">
                      <p className="additional-text"> OLD PARR Blended Scotch Whisky. 40% Alc/Vol.{'\n'} Imported by Diageo, New York, NY.</p>
                    </div>
 
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}
