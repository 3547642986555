import React, {useLayoutEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Nav, Navbar, NavItem } from "react-bootstrap";

import Image from "react-bootstrap/Image";
import { Localhost_Domain } from "../../constant";
import { LinkContainer } from "react-router-bootstrap";
import useWindowDimensions from "./useWindowDimensions";

export default function Menu(props) {
  const [click, setClick] = useState(false);

  const [showNew, setShowNew] = useState('');


  const [show, setShow] = useState(false);
  const navRef = React.useRef("hide");
  
  const { width } = useWindowDimensions();
  const [toggleClass, setToggleClass] = useState(false);

  function handleClick() {
    if (width <= 991) {
      navRef.current.classList.toggle("show");
      navRef.current.classList.toggle("hide");
      setToggleClass((toggleClass) => !toggleClass);
      document.body.classList.toggle("overflowHidden");
      setClick(!click);
    }
  }

  useLayoutEffect(() => {
    function updateSize() {
      setToggleClass(false);
      setClick(false);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  let toggleClassCheck = toggleClass ? "show" : "hide";

  function mouseHover() {
    setShowNew('show');
  }
  function mouseLeave() {
    setShowNew('');
  }
  return (
    <>
      <Navbar variant="dark" className="parrHeader p-0" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Image
              onMouseOver={() => setShow(false)}
              src={`${Localhost_Domain}/img/OldParr-logo.svg`}
              className="img-fluid"
              onClick={handleClick}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onMouseOver={() => setShow(false)}
            onClick={handleClick}
          >
            {/*  */}
            {click ? (
              <span className="closeBtn"> ✖ </span>
            ) : (
              <>
                <span className={click ? "" : "icon-bar m-0"}></span>
                <span className={click ? "" : "icon-bar"}></span>
                <span className={click ? "" : "icon-bar"}></span>
              </>
            )}
          </Navbar.Toggle>
          <div
            className={`nav-bar collapse navbar-collapse ${toggleClassCheck}`}
            id="basic-navbar-nav"
            ref={navRef}
          >
            <Nav className="mx-auto align-items-lg-center">
              <LinkContainer to="/">
                <NavItem
                  className="nav-link"
                  onClick={handleClick}
                  onMouseOver={() => setShow(false)}
                >
                  HOME
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/where-to-buy">
                <NavItem
                  className="nav-link"
                  onClick={handleClick}
                  onMouseOver={() => setShow(false)}
                >
                  WHERE TO BUY
                </NavItem>
              </LinkContainer>
              {/* <NavDropdown title="OUR WHISKIES" id="basic-nav-dropdown" show={show} onMouseOver={showDropdown}>
                <LinkContainer to="/whiskies/old-parr-12-yo">
                  <NavItem className="nav-link" onClick={handleClick} onMouseOver={() => setShow(true)}>OLD PARR AGED 12 YEARS</NavItem>
                </LinkContainer>
                <LinkContainer to="/whiskies/old-parr-18-yo">
                  <NavItem className="nav-link" onClick={handleClick} onMouseOver={() => setShow(true)}>OLD PARR AGED 18 YEARS</NavItem>
                </LinkContainer>
              </NavDropdown> */}
              
              <div
                className={`dropdown whiskies-dropdown d-lg-flex align-items-lg-center`}
                onMouseMove={() => {mouseHover()}}
                onMouseLeave={() => {mouseLeave()}}
              >
                <div
                  className={`nav-link nav-item dropdown-toggle dropdownButton ${showNew}`}
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  OUR WHISKIES
                </div>
                <div
                  className={`dropdown-menu  ${showNew}`}
                  aria-labelledby="dropdownMenuButton"
                >
                  <LinkContainer to="/whiskies/old-parr-12-yo">
                    <NavItem
                      className="nav-link"
                      onClick={() => {
                        handleClick();
                        mouseLeave();
                      }}
                    >
                      OLD PARR AGED 12 YEARS
                    </NavItem>
                  </LinkContainer>
                  <LinkContainer to="/whiskies/old-parr-18-yo">
                    <NavItem className="nav-link" onClick={() => {
                        handleClick();
                        mouseLeave();
                      }}>
                      OLD PARR AGED 18 YEARS
                    </NavItem>
                  </LinkContainer>
                </div>
              </div>
              <LinkContainer to="/the-story">
                <NavItem
                  className="nav-link"
                  onClick={handleClick}
                  onMouseOver={() => setShow(false)}
                >
                  THE STORY
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/the-serves">
                <NavItem
                  className="nav-link"
                  onClick={handleClick}
                  onMouseOver={() => setShow(false)}
                >
                  THE SERVES
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/newsletter">
                <NavItem
                  className="nav-link"
                  onClick={handleClick}
                  onMouseOver={() => setShow(false)}
                >
                  JOIN NOW
                </NavItem>
              </LinkContainer>

              <LinkContainer to="/contact-us">
                <NavItem
                  className="nav-link"
                  onClick={handleClick}
                  onMouseOver={() => setShow(false)}
                >
                  Contact US
                </NavItem>
              </LinkContainer>


              
              {/* For mobile containet */}
              <Nav className="rightNav d-flex d-lg-none">
                <Nav.Link
                  target="blank"
                  href="https://www.facebook.com/oldparrus/"
                >
                  <Image
                    src={`${Localhost_Domain}/img/facebook-icon.png`}
                    className="img-fluid"
                  />
                </Nav.Link>
                <Nav.Link
                  target="blank"
                  href="https://www.instagram.com/oldparr_us/"
                >
                  <Image src={`${Localhost_Domain}/img/instagram-icon.png`} />
                </Nav.Link>
              </Nav>
            </Nav>
            <Nav className="rightNav d-none d-lg-flex">
              <Nav.Link
                target="blank"
                href="https://www.facebook.com/oldparrus/"
              >
                <Image
                  src={`${Localhost_Domain}/img/facebook-icon.png`}
                  className="img-fluid"
                />
              </Nav.Link>
              <Nav.Link
                target="blank"
                href="https://www.instagram.com/oldparr_us/"
              >
                <Image src={`${Localhost_Domain}/img/instagram-icon.png`} />
              </Nav.Link>
            </Nav>
            <p>
              Please do not share with anyone under the legal purchase age for
              alcohol. &nbsp;
              <span className="red-color">Drink Responsibly.</span>
            </p>
          </div>
        </Container>
      </Navbar>
    </>
  );
}