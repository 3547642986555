import React, { useState } from "react";
import { Col, Row, Container } from 'react-bootstrap';
import Image from "react-bootstrap/Image";
import { Localhost_Domain } from "../../constant";
import 'react-multi-carousel/lib/styles.css';
import '../../index.css';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";

// css carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SimpleSlider from "../includes/Carousel";
import NotFound from "./404";
import { motion } from "framer-motion";
export default function TheServeChildComponent() {

    const params = useParams();
    let urlSlug = `${params.slug}`;

    const [images, setImages] = useState([
        {
            title: 'Old Parr Golden',
            img_name: 'OldParrGolden_Slider.jpg',
            slug: "old-parr-golden",
            name: "Old Parr Golden",
            key: 1,
            recipeName: "Old Parr Golden",
            pageTitle: "",
            pageDescription: "",
            excerpt: "",
            thumbnail: "",
            mainImage: "serves/OldParrGolden_Main.jpg",
            mobileImage: "serves/OldParrGolden_Mobile.jpg",
            topImageMobile: "serves/OldParrGolden_Top.jpg",
            servesMobileRecipe: "serves/OldParrGolden_Mobile.jpg",
            dasimg: "serves/dash.png",
            teaserLine: "Transport yourself to your favorite Golden Hour with the exquisite flavor of Old Parr and hints of ginger and lemon.",
            ingredients: [
                "1.5 oz. Old Parr Aged 12 Years",
                "1 oz. ginger syrup",
                "0.5 oz. lemon juice",
                "Top with premium club soda",
                "Orange wedge garnish"
            ],
            preparation: [
                "Combine Old Parr Aged 12 Years",
                "Shake well and strain into a rocks glass over ice",
                "Top with premium club soda and garnish with an orange wedge"
            ]
        },
        {
            title: 'Old Parr Blood and Sand',
            img_name: 'OldParrBloodAndSand_Slider.jpg',
            slug: "old-parr-blood-and-sand",
            name: "Old Parr Blood and Sand",
            key: 2,
            recipeName: "Old Parr Blood and Sand",
            pageTitle: "",
            pageDescription: "",
            excerpt: "",
            thumbnail: "",
            mainImage: "serves/OldParrBloodAndSand_Main.jpg",
            mobileImage: "serves/OldParrBloodAndSand_Mobile.jpg",
            topImageMobile: "serves/OldParrBloodAndSand_Top.jpg",
            servesMobileRecipe: "serves/OldParrBloodAndSand_Mobile.jpg",
            dasimg: "serves/dash.png",
            teaserLine: "Delight in the fruity and smoky taste of this classic cocktail with a twist.",
            ingredients: [
                "1 oz. Old Parr Aged 12 Years",
                "0.75 oz. fresh blood orange juice",
                "0.75 oz. Cherry Heering (or premium cherry liquer)",
                "0.75 oz. sweet vermouth",
                "Brandied cherry garnish"
            ],
            preparation: [
                "Combine Old Parr Aged 12 Years, fresh blood orange juice, premium cherry liqueur and sweet vermouth",
                "Shake with ice and strain into coupe glass",
                "Garnish with brandied cherry"
            ]
        },
        {
            title: 'Old Parr Ginger',
            img_name: 'OldParrGinger_Slider.jpg',
            slug: "old-parr-ginger",
            name: "Old Parr Ginger",
            key: 3,
            recipeName: "Old Parr Ginger",
            pageTitle: "",
            pageDescription: "",
            excerpt: "",
            thumbnail: "",
            mainImage: "serves/OldParrGinger_Main.jpg",
            mobileImage: "serves/OldParrGinger_Mobile.jpg",
            topImageMobile: "serves/OldParrGinger_Top.jpg",
            servesMobileRecipe: "serves/OldParrGinger_Mobile.jpg",
            dasimg: "serves/dash.png",
            teaserLine: "Enjoy the sweet and spicy flavors of Ginger Ale, paired with Old Parr and garnished with a slice of orange.",
            ingredients: [
                "1.5 oz. Old Parr Aged 12 Years",
                "3 oz. premium ginger ale",
                "Squeeze of orange",
                "Orange wedge garnish"
            ],
            preparation: [
                "Fill a highball glass with ice",
                "Pour Old Parr Aged 12 Years",
                "Top with premium ginger ale",
                "Add a squeeze of orange, stir and garnish with an orange wedge"
            ]
        },
        {
            title: 'Old Parr Picoso',
            img_name: 'OldParrPicoso_Slider.jpg',
            slug: "old-parr-picoso",
            name: "Old Parr Picoso",
            key: 4,
            recipeName: "Old Parr Picoso",
            pageTitle: "",
            pageDescription: "",
            excerpt: "",
            thumbnail: "",
            mainImage: "serves/OldParrPicoso_Main.jpg",
            mobileImage: "serves/OldParrPicoso_Mobile.jpg",
            topImageMobile: "serves/OldParrPicoso_Top.jpg",
            servesMobileRecipe: "serves/OldParrPicoso_Mobile.jpg",
            dasimg: "serves/dash.png",
            teaserLine: "Enjoy the spicy yet refreshing taste of this delicious cocktail.",
            ingredients: [
                "1.5 oz. Old Parr Aged 12 Years",
                "0.5 oz. fresh lemon juice",
                "0.75. oz. fresh grapefruit juice",
                "0.75 oz. hot honey syrup",
                "Sage bouquet garnish"
            ],
            preparation: [
                "Combine Old Parr Aged 12 Years, fresh lemon juice, fresh grapefruit juice, and spicy honey syrup",
                "Shake with ice, strain into rocks glass",
                "Garnish with sage bouquet"
            ]
        },
        {
            title: 'Old Parr & Cola',
            img_name: 'OldParrCola_Slider.jpg',
            slug: "old-parr-and-cola",
            name: "Old Parr & Cola",
            key: 5,
            recipeName: "Old Parr & Cola",
            pageTitle: "",
            pageDescription: "",
            excerpt: "",
            thumbnail: "",
            mainImage: "serves/OldParrCola_Main.jpg",
            mobileImage: "serves/OldParrCola_Mobile.jpg",
            topImageMobile: "serves/OldParrCola_Top.jpg",
            servesMobileRecipe: "serves/OldParrCola_Mobile.jpg",
            dasimg: "serves/dash.png",
            teaserLine: "Enjoy your golden moments with Old Parr and a splash of cola.",
            ingredients: [
                "1.5 oz. Old Parr Aged 12 Years",
                "3 oz. premium cola",
                "Squeeze of lime",
                "Lime wedge garnish"
            ],
            preparation: [
                "Fill a highball glass with ice",
                "Pour Old Parr Aged 12 Years",
                "Top with premium cola",
                "Add a squeeze of lime, stir and garnish with a lime wedge"
            ]
        },
        {
            img_name: 'TheOGP_Main_Slider.jpg',
            title: "The Og P",
            slug: "the-og-p",
            name: "The Og P",
            key: 6,
            recipeName: "The Og P",
            pageTitle: "",
            pageDescription: "",
            excerpt: "",
            thumbnail: "",
            mainImage: "serves/TheOGP_Main.jpg",
            mobileImage: "serves/TheOGP_Mobile.jpg",
            topImageMobile: "serves/TheOGP_Top.jpg",
            servesMobileRecipe: "serves/TheOGP_Mobile.jpg",
            dasimg: "serves/dash.png",
            teaserLine: "We don’t call it the OG for nothing. This delicious tropical cocktail will awaken the rhythm of your Latin Soul.",
            ingredients: [
                "1.5 oz. Old Parr Aged 12 Years",
                "3 oz. fresh pineapple juice",
                "0.65 oz ginger syrup",
                "Top with ginger beer",
                "Slice of ginger and/or pineapple leaves garnish"
            ],
            preparation: [
                "Combine Old Parr Aged 12 Years",
                "fresh pineapple juice",
                "and ginger syrup into a cocktail shaker with ice",
                "Shake well and strain into a highball glass over ice",
                "Top with ginger beer and garnish with a slice of ginger and/or pineapple leaves"
            ]
        },
    ]);

    // filter with 1 condition
    const filtered = images.filter(images => {
        return images.slug === urlSlug;
    });
    const result = images.find(({ slug }) => slug === urlSlug);

    if (filtered.length === 0) {
        return (
            <div>
                <NotFound />
            </div>
        )
    }
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Grand Old Parr Scotch</title>
                </Helmet>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{
                        duration: 0.6,
                        ease: [0, 0.71, 0.2, 1.01],
                    }}
                    exit={{ opacity: 0 }}
                    >
                <div className="serve-child-conitainer">
                    <div>
                        <Image src={`${Localhost_Domain}/img/${result.topImageMobile}`} className='img-fluid d-sm-none d-block'  style={{width: '100%'}}/>
                    </div>
                    <Container className="child-bg">
                        <Row>
                            <Col lg={12} sx={12}>
                                <div className="head-style">
                                    <h1 className='mt-5'>
                                        {result.title.toUpperCase()}
                                    </h1>
                                    <p className='mt-3'>
                                        {result.teaserLine}
                                    </p>
                                </div>
                            </Col>
                            <Col lg={12} sx={12} className='d-flex justify-content-center'>
                                <div className="img-div">
                                    <div className="border-div"></div>
                                    <Image src={`${Localhost_Domain}/img/${result.mobileImage}`} className='img-fluid d-sm-none d-block' />
                                    <Image src={`${Localhost_Domain}/img/${result.mainImage}`} className='img-fluid d-sm-block d-none' />
                                    <div className="img-desc">
                                        <div className="firstDiv">
                                            <h1><span className="text-danger">I</span>NGREDIENTS</h1>
                                            {/* <ul> */}
                                            <ul>
                                                {
                                                    result.ingredients.map((value, i) => {
                                                        return <li key={i} className='dash-img-display'> <span className='dash-image'><Image src={`${Localhost_Domain}/img/${result.dasimg}`} className='dash-img' /></span> {value}</li>
                                                    })
                                                }
                                                </ul>
                                            {/* </ul> */}
                                        </div>
                                        <div className="secondDiv">
                                            <h1 className="mt-sm-5 mt-3 mt-md-0"><span className="text-danger">H</span>OW TO PREPARE</h1>
                                            {/* <ul> */}
                                            <ol>
                                                {
                                                    result.preparation.map((value, i) => {
                                                        return <li key={i}>{value}</li>
                                                    })
                                                }
                                                </ol>
                                            {/* </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Row className="m-0">
                        <div className="container-slider">
                            <SimpleSlider {...images} />
                        </div>
                    </Row>
                </div>
                </motion.div>
            </HelmetProvider>
        </>
    );
}