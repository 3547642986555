import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { Localhost_Domain } from "../../constant";
import Image from "react-bootstrap/Image";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//import { useNavigate } from "react-router-dom";

const ResponsiveHome = () => {
  const slider = useRef();

  //set initial values empty to array
  const [data, setData] = useState([]);

  //function for set data to variable
  const getData = async() => {
    await fetch("whiskies/whiskies.json", {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
      },
    }).then(function (response) {
        return response.json();
      }).then(function (myJson) {
        setData(myJson);
      });
  };

  //Funcation call onece for data loading
  useEffect(() => {
    getData();
  }, []);

  const whisky = data.whiskies;
  const whisky_result = whisky ? whisky : [];
  // console.log(whisky_result);
  var settings = {
    dots: false,
    infinite: true,
    fade: true,
    speed: 2200,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    swipe: true,
    // arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      {/* <Button className="btn homeSliderPrev" onClick={previous}> Prev Button </Button> */}
      <Slider ref={(c) => (slider.current = c)} {...settings}>
        {Object.values(whisky_result).map((elm) => (
          <div key={elm.slug}>
            <div className="sliderContain">
              <div className="brandImg">
                <Image
                  src={`${Localhost_Domain}/img/whisky/${elm.homeSliderImg}`}
                  className="img-fluid dDekstop"
                />
                <Image
                  src={`${Localhost_Domain}/img/whisky/${elm.homeSliderImgM}`}
                  className="img-fluid dMobaile d-none"
                />
              </div>

              <div className="brnadDesc">
                <h1>OLD PARR</h1>
                <div className="brandAge d-flex align-items-center justify-content-between">
                  <h2>AGED</h2>
                    <Image
                      src={`${Localhost_Domain}/img/whisky/${elm.ageImage}`}
                      className="img-fluid"
                    />
                  <h2>YEARS</h2>
                </div>
              <div className="mt-4 awardsImage">
                 <div className="d-flex justify-content-center">
                 <Image
                    src={`${Localhost_Domain}/img/coins/${elm.RedDoubleGoldMed}`}
                    className="img-fluid me-2"
                  />
                  <Image
                    src={`${Localhost_Domain}/img/coins/${elm.DoubleGoldMed}`}
                    className="img-fluid"
                  />
                 </div>
                  <Link to={`/whiskies/${elm.slug}`}>
                    <Button className="btn-danger">{elm.name}</Button>
                  </Link>
                 </div>
              </div>
            </div>
          </div> 
        ))}
      </Slider>
    </div>
  );
};

export default ResponsiveHome;