import React, { useState, useEffect } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import "../../index.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NewsletterSubmit from "./NewsletterSuccess";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { Localhost_Domain } from "../../constant";
// import differenceInYears from 'date-fns/difference_in_years'
const parse = require("html-react-parser");

export default function Newsletter() {
  const location = useLocation();
  const checkRoute = location.pathname;
  let routUrl = Localhost_Domain + checkRoute;
  const [showSubmit, setShowSubmit] = useState(false);
  const [responseApi, setResponseApi] = useState({});

  const [gToken, setGToken] = useState("");
  const [isGtoken, setIsGToken] = useState(false);

  const state = [
    {
      key: "AL",
      value: "Alabama",
    },
    {
      key: "AK",
      value: "Alaska",
    },
    {
      key: "AZ",
      value: "Arizona",
    },
    {
      key: "AR",
      value: "Arkansas",
    },
    {
      key: "CA",
      value: "California",
    },
    {
      key: "CO",
      value: "Colorado",
    },
    {
      key: "CT",
      value: "Connecticut",
    },
    {
      key: "DE",
      value: "Delaware",
    },
    {
      key: "DC",
      value: "District of Columbia",
    },
    {
      key: "FL",
      value: "Florida",
    },
    {
      key: "GA",
      value: "Georgia",
    },
    {
      key: "HI",
      value: "Hawaii",
    },
    {
      key: "ID",
      value: "Idaho",
    },
    {
      key: "IL",
      value: "Illinois",
    },
    {
      key: "IN",
      value: "Indiana",
    },
    {
      key: "IA",
      value: "Iowa",
    },
    {
      key: "KS",
      value: "Kansas",
    },
    {
      key: "KY",
      value: "Kentucky",
    },
    {
      key: "LA",
      value: "Louisiana",
    },
    {
      key: "ME",
      value: "Maine",
    },
    {
      key: "MD",
      value: "Maryland",
    },
    {
      key: "MA",
      value: "Massachusetts",
    },
    {
      key: "MI",
      value: "Michigan",
    },
    {
      key: "MN",
      value: "Minnesota",
    },
    {
      key: "MS",
      value: "Mississippi",
    },
    {
      key: "MO",
      value: "Missouri",
    },
    {
      key: "MT",
      value: "Montana",
    },
    {
      key: "NE",
      value: "Nebraska",
    },
    {
      key: "NV",
      value: "Nevada",
    },
    {
      key: "NH",
      value: "New Hampshire",
    },
    {
      key: "NJ",
      value: "New Jersey",
    },
    {
      key: "NM",
      value: "New Mexico",
    },
    {
      key: "NY",
      value: "New York",
    },
    {
      key: "NC",
      value: "North Carolina",
    },
    {
      key: "ND",
      value: "North Dakota",
    },
    {
      key: "OH",
      value: "Ohio",
    },
    {
      key: "OK",
      value: "Oklahoma",
    },
    {
      key: "OR",
      value: "Oregon",
    },
    {
      key: "PA",
      value: "Pennsylvania",
    },
    {
      key: "RI",
      value: "Rhode Island",
    },
    {
      key: "SC",
      value: "South Carolina",
    },
    {
      key: "SD",
      value: "South Dakota",
    },
    {
      key: "TN",
      value: "Tennessee",
    },
    {
      key: "TX",
      value: "Texas",
    },
    {
      key: "UT",
      value: "Utah",
    },
    {
      key: "VT",
      value: "Vermont",
    },
    {
      key: "VA",
      value: "Virginia",
    },
    {
      key: "WA",
      value: "Washington",
    },
    {
      key: "WV",
      value: "West Virginia",
    },
    {
      key: "WI",
      value: "Wisconsin",
    },
    {
      key: "WY",
      value: "Wyoming",
    },
  ];

  const handleCallback = (childData) => {
    setShowSubmit(childData);
  };

  // const date = new Date().toISOString().split("T")[0];
  // function minDate() {
  //   const date = new Date();
  //   date.setFullYear(date.getFullYear() - 125);
  //   return date.toISOString().split("T")[0];
  // }

  // function differenceInYears(inputDate) {
  //   if (new Date().getFullYear() < inputDate.getFullYear()) {
  //     return 0;
  //   }
  //   const ageDifMs = Date.now() - inputDate.getTime();
  //   const ageDate = new Date(ageDifMs); // miliseconds from current and input date
  //   const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970); //calculate age
  //   return ageInYears;
  // }

  //   site key:6LclIMYlAAAAAPoi0LYyl2GEMyR96m-esGU2EQLP
// secrate: 6LclIMYlAAAAAGFFco1pNqL6mVQOzcbZgLdkbFcb
  useEffect(() => {
    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "event": "sign_up_step",
      "step": "form shown",
      "sign_up_type": "newsletter",
      "form_name": "newsletter",
      "form_location": "inline"
    });

    console.log(window.dataLayer);
    
    setTimeout(
      () => {
        if (window.grecaptcha.ready) {
          window.grecaptcha.execute('6LclIMYlAAAAAPoi0LYyl2GEMyR96m-esGU2EQLP', { action: 'submit' }).then(token => {
            // submitData(token);
            // console.log(token)
            setGToken(token);
            setIsGToken(true);
          });
        }
      },
      5000);
  }, []);


  const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age_now--;
    }
    return age_now;
  }

  // function invalidDateCheck(inputDate) {
  //   const ageDifMs = Date.now() - inputDate.getTime();
  //   const ageDate = new Date(ageDifMs); // miliseconds from current and input date
  //   const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970); //calculate age
  //   return ageInYears;
  // }

  //first name max 50
  //last name 80 character only


  // useEffect(() => {
  //   setTimeout(
  //     () => {
  //       if (window.grecaptcha.ready) {
  //         window.grecaptcha.execute('6Ld_GsYlAAAAAG6sAH5EPuAWZDmoOJUnerI3x7Oi', { action: 'submit' }).then(token => {
  //           // submitData(token);
  //           // console.log(token)
  //           setGToken(token);
  //           setIsGToken(true);
  //         });
  //       }
  //     },
  //     5000);
  // }, []);


  const newsletterSchema = Yup.object().shape({
    CP_FirstName: Yup.string()
      .required("First Name is required.")
      .max(50, 'First Name is Too Long!')
      .matches(
        /^[a-zA-Z]+$/,
        "First Name cannot contain white space and special character"
      ),
    CP_LastName: Yup.string()
      .max(80, 'Last Name is Too Long!')
      .matches(
        /^[a-zA-Z]+$/,
        "Last Name cannot contain white space and special character"
      )
      .required("Last Name is required."),
    CP_DOB: Yup.string()
      .required("DOB is required.")
      .test(
        "DOB",
        "You must be at least 21 years old to enter.",
        function (value) {
          // console.log(new Date(value));
          // return differenceInYears(new Date(value)) >= 21;
          return calculate_age(new Date(value)) >= 21;
        }
      )
      .test(
        "DOB",
        "Date of birth is out of range.",
        function (value) {
          return calculate_age(new Date(value)) < 125;
        }
      ),
    CP_EmailId: Yup.string()
      .email("Please enter valid email address.")
      .required("Email is required."),

    PR_4_324_1_freetext: Yup.string()
      .matches(
        /^[0-9]+$/,
        "Please provide valid zip code!"
      )
      .required("Zip Code is required."),

    PR_4_466_1_freetext: Yup.string().required("State Code is required."),
   /* PR_1_64_1: Yup.bool().oneOf([true], "Please tick this box if you want to proceed."),*/
    G_Token: Yup.string(),
    PR_1_64_2: Yup.bool().oneOf([true], "Please tick this box if you want to proceed."),
    /*newsletter_optin_terms: Yup.bool().oneOf(
      [true],
      "Please tick this box if you want to proceed."
    ),*/
  });


  const formik = useFormik({
    initialValues: {
      PR_4_367_1_freetext: "",
      CP_FirstName: "",
      CP_LastName: "",
      CP_DOB: "",
      CP_EmailId: "",
      PR_4_466_1_freetext: "",
      PR_4_324_1_freetext: "",
      //PR_1_64_1: false,
      PR_1_64_2: false,
      //newsletter_optin_terms: false,
      CP_WebsiteUrl: routUrl,
      G_Token: ""
    },
    validationSchema: newsletterSchema,
    onSubmit: async (values, { resetForm }) => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LclIMYlAAAAAPoi0LYyl2GEMyR96m-esGU2EQLP', { action: 'submit' }).then(token => {
          // submitData(token);
          setGToken(token);
          setIsGToken(true);
        });
      });
      var dataForm = {
        ContactKey: values.CP_EmailId,
        EventDefinitionKey: "APIEvent-a5e397d8-3580-77b4-40c7-2f6ee5437c7f",
        Data: {
          firstName: values.CP_FirstName,
          lastName: values.CP_LastName,
          dateOfBirth: values.CP_DOB,
          emailAddress: values.CP_EmailId,
          postCode: values.PR_4_324_1_freetext,
          province: values.PR_4_466_1_freetext,
         // emailable: values.PR_1_64_1 === true ? true : false,
          partnerOptIn: values.PR_1_64_2 === true ? true : false,
          country: "US",
          privacyPolicyVersion: "14.0",
          subscriberKey: values.CP_EmailId,
          brand: "Old Parr",
          brandAbbreviation: "OLP",
          G_Token: "",
        }
      };

      if (isGtoken) {
        dataForm.Data.G_Token = gToken;
      }

      let eHheader = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
        crossdomain: true,
        // methods: ['POST', 'GET', 'PATCH', 'DELETE', 'OPTIONS'],
        allowedHeaders: ['Content-Type', 'Authorization'],
      }
      
      var optinBrand = "oldparr";
      if (dataForm.Data.partnerOptIn) {
        optinBrand = "oldparr | thebar";
      }
      
      console.log(optinBrand);


      await axios
        .post("api/request.php", dataForm, eHheader)
        .then(function (result) {
          if (result.data.eventInstanceId) {
            result.data.success = true;
            result.data.message = "Thank you for singing up for our newsletter.";
            setResponseApi(result.data);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              "event": "sign_up",
              "step": "success",
              "sign_up_type": "newsletter",
              "form_name": "newsletter",
              "form_location": "inline",
              "detail": "newsletter opt-in",
              "optin_brands": optinBrand
            });
            console.log(window.dataLayer);
          } else {
            result.data.success = false;
            setResponseApi(result.data);
          }
          setShowSubmit(true);
        })
        .catch(function (e) {
          console.log("Network Error msg", e.response);
          setResponseApi(e);
          setShowSubmit(true);
        })
    },
  });
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Newsletter</title>
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
        >
          {showSubmit && showSubmit === true ? (
            <NewsletterSubmit
              apiResponse={responseApi}
              isShowRegisterForm={handleCallback}
            />
          ) : (
            <section className="newsLetter-container newsLetter">
              <div id="newsletter">
                <Container style={{ maxWidth: "1140px" }} className="pt-4">
                  <div className="justify-content-center stayTouch">
                    <div className="d-flex justify-content-center text-align-center">
                      <h1>
                        <span className="text-danger">S</span>tay in Touch
                      </h1>
                    </div>
                    <p>
                      Sign up today for news, exclusive recipes and the latest
                      events.
                    </p>
                  </div>

                  <div className="justify-content-center">
                    <Form className="touchForm" id="cidbForm">
                      <Row>
                        <Form.Group
                          className="mb-3 input"
                          controlId="PR_4_367_1_freetext"
                        >
                          <Form.Control
                            type="hidden"
                            name="PR_4_367_1_freetext"
                            value="10"
                          />
                        </Form.Group>
                        <Col sm={6} xs={12}>
                          <Form.Group
                            className="mb-3 input"
                            controlId="CP_FirstName"
                          >
                            <Form.Control
                              name="CP_FirstName"
                              value={formik.values.CP_FirstName}
                              onChange={formik.handleChange("CP_FirstName")}
                              placeholder="First Name*"
                            />
                            {formik.errors.CP_FirstName &&
                              formik.touched.CP_FirstName ? (
                              <span className="required_text">
                                {formik.errors.CP_FirstName}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group
                            className="mb-3 input"
                            controlId="CP_LastName"
                          >
                            <Form.Control
                              name="CP_LastName"
                              value={formik.values.CP_LastName}
                              onChange={formik.handleChange("CP_LastName")}
                              placeholder="Last Name*"
                            />
                            {formik.errors.CP_LastName &&
                              formik.touched.CP_LastName ? (
                              <span className="required_text">
                                {formik.errors.CP_LastName}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group className="mb-3 input" controlId="CP_DOB">
                            <Form.Control
                              className={
                                formik.values.CP_DOB ? "rem-placeholder" : ""
                              }
                              name="CP_DOB"
                              type="date"
                              selected={
                                (formik.values.CP_DOB &&
                                  new Date(formik.values.CP_DOB)) ||
                                null
                              }
                              value={formik.values.CP_DOB}
                              onChange={formik.handleChange("CP_DOB")}
                              placeholder="dd-mm-yyyy*"
                              max="1901-12-31"
                            />
                            {formik.errors.CP_DOB && formik.touched.CP_DOB ? (
                              <span className="required_text">
                                {formik.errors.CP_DOB}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group
                            className="mb-3 input"
                            as={Col}
                            controlId="CP_EmailId"
                          >
                            <Form.Control
                              name="CP_EmailId"
                              type="email"
                              value={formik.values.CP_EmailId}
                              onChange={formik.handleChange("CP_EmailId")}
                              placeholder="Email*"
                            />
                            {formik.errors.CP_EmailId &&
                              formik.touched.CP_EmailId ? (
                              <span className="required_text">
                                {formik.errors.CP_EmailId}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col sm={6} xs={12}>
                          <Form.Group
                            className="mb-3 input"
                            as={Col}
                            controlId="PR_4_324_1_freetext"
                          >
                            <Form.Control
                              name="PR_4_324_1_freetext"
                              value={formik.values.PR_4_324_1_freetext}
                              onChange={formik.handleChange(
                                "PR_4_324_1_freetext"
                              )}
                              placeholder="Zip Code*"
                              maxLength="5"
                            />
                            {formik.errors.PR_4_324_1_freetext &&
                              formik.touched.PR_4_324_1_freetext ? (
                              <span className="required_text">
                                {formik.errors.PR_4_324_1_freetext}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col sm={6} xs={12}>
                          <Form.Group
                            className="mb-3 input"
                            as={Col}
                            controlId="PR_4_466_1_freetext"
                          >
                            <Form.Select
                              name="PR_4_466_1_freetext"
                              className="input"
                              value={formik.values.PR_4_466_1_freetext}
                              onChange={formik.handleChange(
                                "PR_4_466_1_freetext"
                              )}
                            >
                              <option
                                defaultValue=""
                                disabled=""
                                hidden=""
                                value=""
                              >
                                State*
                              </option>
                              {state.length > 0 &&
                                state.map((option) => (
                                  <option key={option.key} value={option.key}>
                                    {option.value}
                                  </option>
                                ))}
                            </Form.Select>
                            {formik.errors.PR_4_466_1_freetext &&
                              formik.touched.PR_4_466_1_freetext ? (
                              <span className="required_text">
                                {formik.errors.PR_4_466_1_freetext}
                              </span>
                            ) : null}
                          </Form.Group>
                        </Col>

                        {/*<Form.Group className="input" id="PR_1_64_1">
                          <Form.Check
                            className={
                              formik.errors.PR_1_64_1 &&
                                formik.touched.PR_1_64_1
                                ? "error"
                                : ""
                            }
                            name="PR_1_64_1"
                            value={formik.values.PR_1_64_1}
                            checked={formik.values.PR_1_64_1}
                            onChange={formik.handleChange("PR_1_64_1")}
                            type="checkbox"
                            id="first-check"
                            label="* Yes, I would like to receive special offers and promotions from Old Parr by email."
                          />
                        </Form.Group>
                        {formik.errors.PR_1_64_1 &&
                        formik.touched.PR_1_64_1 ? (
                          <span className="required_text mt-2 ps-4">
                            {formik.errors.PR_1_64_1}
                          </span>
                        ) : null}  */}
                        
                        <Form.Group className="input" id="PR_1_64_2">
                          <Form.Check
                            className={
                              formik.errors.PR_1_64_2 &&
                                formik.touched.PR_1_64_2
                                ? "error"
                                : ""
                            }
                            name="PR_1_64_2"
                            value={formik.values.PR_1_64_2}
                            checked={formik.values.PR_1_64_2}
                            type="checkbox"
                            id="second-check"
                            onChange={formik.handleChange("PR_1_64_2")}
                            label={parse(
                              "* Tick here if you would like us to use your email to keep you informed about products, services and events from Old Parr & other Diageo brands including <a href='https://www.thebar.com/en-gb' target='_blank' className='red-color'>TheBar.com</a>. You can unsubscribe at any time. By signing up, you accept Diageo's <a className = 'red-color' href='https://footer.diageohorizon.com/dfs/assets/www.oldparrscotch.com/TnC_uen.html?locale=uen-us' target='_blank'>Conditions of Use</a> and acknowledge the <a className = 'red-color' href='https://footer.diageohorizon.com/dfs/assets/www.oldparrscotch.com/PrivacyPolicy_uen.html?locale=uen-us' target='_blank'>Privacy & Cookie Notice</a>."
                            )}
                          />
                        </Form.Group>

                        { /* <Form.Group
                          className="input"
                        >
                          <Form.Check
                            className={
                              formik.errors.newsletter_optin_terms &&
                                formik.touched.newsletter_optin_terms
                                ? "error"
                                : ""
                            }
                            name="newsletter_optin_terms"
                            value={formik.values.newsletter_optin_terms}
                            checked={formik.values.newsletter_optin_terms}
                            type="checkbox"
                            id="newsletter_optin_terms"
                            onChange={formik.handleChange(
                              "newsletter_optin_terms"
                            )}
                            label={parse(
                              "* Tick here if you would like us to use your email to keep you informed about products, services and events from Old Parr & other Diageo brands including TheBar.com. You can unsubscribe at any time. By signing up, you accept Diageo's <a className = 'red-color' href='https://footer.diageohorizon.com/dfs/assets/www.oldparrscotch.com/TnC_uen.html?locale=uen-us' target='_blank'>Conditions of Use</a> and acknowledge the <a className = 'red-color' href='https://footer.diageohorizon.com/dfs/assets/www.oldparrscotch.com/PrivacyPolicy_uen.html?locale=uen-us' target='_blank'>Privacy & Cookie Notice</a>."
                            )}
                          />
                        </Form.Group> */}

                        <Form.Group className="btnDiv">
                          <Button
                            className="btn"
                            type="submit"
                            onClick={formik.handleSubmit}
                          >
                            SUBMIT
                          </Button>
                        </Form.Group>

                        <div className="smallText">
                          <small>
                            Old Parr values and respects your privacy. You can
                            unsubscribe at any time.
                          </small>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </Container>
              </div>
            </section>
          )}
        </motion.div>
      </HelmetProvider>
    </>
  );
}