import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';

function SuccessModal(props) {

  const [isOpen, setIsOpen] = useState(props.isShowModal);
  const handleClose = (event) => { props.registerModel(true); event.preventDefault(); };
  const handleThisClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      document.body.style.overflow = 'unset';
    }, 1500
    );
  };
  return (
    <>
      <Modal show={isOpen} size="lg" className='newsLetter' centered>
        <Modal.Header className="border-0 pb-0 m-auto">
          <div className="stayTouch text-center">
            <h1><span className="text-danger">S</span>tay in Touch</h1>
            <p>
              Sign up today for news, exclusive recipes and the latest
              events.
            </p>
            <button onClick={handleThisClose} type="button" className="btn closeBtn">✖</button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="stayTouch text-center">

            <h2>{props.apiResponse.success ? <span><span className="text-danger">S</span>uccess!</span> : <span><span className="text-danger">E</span>rror!</span>}</h2>
            <p className='colorRed mb-2'>{props.apiResponse.success ? "Thank you for singing up for our newsletter." : props.apiResponse.message }</p>
          </div>
        </Modal.Body>
        { props.apiResponse.success ? "" : <button onClick={handleClose} className='colorRed ms-2 backBtn' >Back</button> }
      </Modal>
    </>
  );
}

export default SuccessModal;