import React from "react";
import { Col, Row, Form, Button, Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

function FooterOrderNow() {
  const openInNewTab = (url) => {
    // 👇️ setting target to _blank with window.open
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const location = useLocation(); 
  
  return (
    <>
     { 
     location.pathname !== '/where-to-buy/' ? <div className="near-you-conitainer">
        <Container style={{ maxWidth: "960px", width: "100%" }}>
          <div className="">
            <div className="nearYou">
              <h2 className="text-start">NEAR YOU</h2>
              <div className="nearYouText">
                <Form.Group
                  className="input zipCode"
                  controlId="formGridZipCode">
                  <Form.Control name="zipCode" placeholder="Zip Code*" />
                </Form.Group>
                <Form.Group className="input" controlId="formGridState">
                  <Form.Select name="state">
                    <option value="Old Parr Aged 12 Years">
                      Old Parr Aged 12 Years
                    </option>
                    <option value="Old Parr Tribute">Old Parr Tribute</option>
                  </Form.Select>
                </Form.Group>
                <Link className="near-you btn-danger" to="/where-to-buy/"><Button className="" type="submit">Submit</Button></Link>
              </div>
            </div>
          </div>
        </Container>
      </div>: ""
      }

      <div className="order-conitainer">
        <Container style={{ maxWidth: "960px", width: "100%" }}>
          <Row className="align-items-center">
            <Col lg={5} md={12} >
              <h2 className="">Order Now</h2>
            </Col>
            <Col lg={7} md={12} className='mt-12'>
              <div className="buttons text-md-right text-start">
                <a
                  rel="noopener noreferrer"
                  href="#"
                  onClick={() =>
                    openInNewTab(
                      "https://drizly.com/liquor-brands/grand-old-parr/b3479"
                    )
                  }
                >
                  <Button className="me-2 text-white">Drizly</Button>
                </a>
                <a
                  rel="noopener noreferrer"
                  href="#"
                  onClick={() =>
                    openInNewTab(
                      "https://minibardelivery.com/store/product/grand-old-parr-scotch/grand-old-parr-12-year-750ml"
                    )
                  }
                >
                  <Button>Minibar</Button>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
export default FooterOrderNow;
