import React, { useState, useEffect } from "react";
import Select from "react-select";

const MultiDropdown = ({ name, selectedOptions: initialSelectedOptions, onChange }) => {
  const [selectedOptions, setSelectedOptions] = useState(initialSelectedOptions || []);

  // Update state when parent component provides new selected options
  useEffect(() => {
    setSelectedOptions(initialSelectedOptions || []);
  }, [initialSelectedOptions]);

  const multiDropOptions = [
    { value: "Grand Old Parr - Aged 12 Years Old", label: "Grand Old Parr - Aged 12 Years Old" },
    { value: "Grand Old Parr - Aged 18 Years Old", label: "Grand Old Parr - Aged 18 Years Old" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '1rem ', // Set the font size to 14 pixels
      fontFamily: '"Basic Commercial LT W05 Roman"',
      padding: '0px 10px',
      color: '#000',
      letterspacing: '0.9px',
      lineheight: '1.1',
      textrendering: 'geometricPrecision',
      margin: '0',
      ':hover': {
       backgroundColor: '#1a73e8', // Change background color on hover
       color: '#fff', // Change text color on hover
      },
     }),
     
  };
  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions);
  };

  return (
    <div>
      <Select
        isMulti
        inputId={name}
        inputProps={{ name }}
        value={selectedOptions}
        onChange={handleChange}
        options={multiDropOptions}
        className="mb-3"
        placeholder="Select One Or More Product*" 
        styles={customStyles}
      />
      <input type="hidden" name={name} value={selectedOptions.map(item => item.value)} />
      {/* JSON.stringify(selectedOptions) */}
    </div>
  );

};

export default MultiDropdown;
