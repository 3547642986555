import React from "react";
import { Container } from "react-bootstrap";

export default function NewsletterSuccess(props) {

  const handleClose = (event) => { props.isShowRegisterForm(false); event.preventDefault(); };

  return (
    <>
      <div>
        <section className="newsLetter pb-5 mb-3">
          <Container className="mx-auto" style={{ maxWidth: "1000px" }}>
            <div className="stayTouch text-center">
              <h1>Stay in Touch</h1>
              <p>
                Sign up today for news, exclusive recipes and the latest events.
              </p>
            </div>

            <div className="justify-content-center text-center">
              <div className="stayTouch text-center">
                <h2>{props.apiResponse.success ? <span><span className="text-danger">S</span>uccess!</span> : <span><span className="text-danger">E</span>rror!</span>}</h2>
                <p className="colorRed mb-2">
                 { props.apiResponse.success ? "Thank you for singing up for our newsletter." : props.apiResponse.message }
                </p>
              </div>
              { props.apiResponse.success ? "" : <button onClick={handleClose} className='colorRed ms-2 mt-3
               backBtn' >Back</button> }
              {/* isShowRegisterForm */}
            </div>
          </Container>
        </section>
      </div>
    </>
  );
}
