import React from "react";
import { Col, Row, Button, Container } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Localhost_Domain } from "../constant";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import { Helmet, HelmetProvider } from "react-helmet-async";

// css carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ResponsiveHome from "./includes/CarouselHome";
import { motion } from "framer-motion";
export default function Home() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Grand Old Parr Scotch</title>
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
      >
        <div className="home-conitainer">
          <section className="firsct-conitainer">
            <div className="imgText-bg">
              <Container style={{ maxWidth: "1160px" }}>
                <Row className="pt-5" style={{position: 'relative'}}>
                  <Col sm={7} xs={10} className="d-flex">
                    <div className="descText">
                      <h1>
                        <span className="text-danger">T</span>HE DELUXE WHISKY WITH
                        A SCOTTISH HEART AND A LATIN SOUL.
                      </h1>
                      <p>
                        Old Parr is a blended Scotch whisky with a smooth and
                        accessible flavor that is perfect for sharing.
                      </p>
                      <Link to="/the-whisky/">
                        <Button className="btn-danger" style={{position: 'relative', zIndex: '9'}}>DISCOVER OUR WHISKY</Button>
                      </Link>
                    </div>
                  </Col>
                  <Col sm={5} xs={5} className='bottleDiv'>
                    <Image src={`${Localhost_Domain}/img/whisky/bottle-updated.png`} className='img-fluid' /> 
                    {/* <Image src={`${Localhost_Domain}/img/whisky/bottleSmall-hd.png`} className='img-fluid m' /> */}
                  </Col>
                </Row>
              </Container>
            </div>
          </section>

          <section className="second-conitainer">
            <div className="bgImage">
              <Container>
                <Row className="sec-row">
                  <Col lg={7} md={12} sm={12} className='d-flex align-items-center'>
                    <Image
                      src={`${Localhost_Domain}/img/home/Portrait_of_Thomas_Parr.png`}
                      className="img-fluid inspiredImg m-lg-0 m-auto"
                    />
                    <div className="inspiredText forMobile50 d-none">
                      <h2>
                        <span className="text-danger">I</span>NSPIRED BY THE LEGEND
                        OF OLD THOMAS PARR.
                      </h2>
                    </div>
                  </Col>
                  <Col lg={5} md={12} sm={12} className="d-flex align-items-center mt-lg-0 mt-5">
                    <div className="inspiredText">
                      <h2 className='forDekstop50'>
                        <span className="text-danger">I</span>NSPIRED BY THE LEGEND
                        OF OLD THOMAS PARR.
                      </h2>
                      <p>
                        Old Parr was named after the legendary figure of Thomas
                        Parr, said to be England’s longest-lived man, aged 152 when
                        he died in 1635. His long life symbolized the maturity of
                        the whiskies used in the blend, whilst his wisdom
                        represented the skill and knowledge required to produce it.
                      </p>
                      <Link to="/the-story/"> 
                        <Button className="btn-danger" style={{position: 'relative', zIndex: '9'}}>LEARN MORE</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </section>

          <section className="third-conitainer">
            <div className="whiskeyCocktail-bg">
              <Container>
                <div>
                  <h2>
                    <span className="text-danger">W</span>HISKY COCKTAILS
                  </h2>
                </div>
              </Container>
              <div className="imgDescDiv container">
                <Row>
                  <Col lg={5} md={12} className="old-par-bottle">
                    <div className='text-center'>
                      <Image
                        src={`${Localhost_Domain}/img/TheOGPHome_large.jpg`}
                        alt="Old Parr Bottle"
                        className="img-fluid"
                        width={'100%'}
                      />
                    </div>
                  </Col>
                  <Col lg={1} className='d-none d-lg-flex'></Col>
                  <Col lg={6} md={12}>
                    <div className="head-style">
                      <h2>
                        <span className="text-danger">T</span>HE OG P
                      </h2>
                      <p>
                        We don’t call it the OG for nothing. This <br /> delicious tropical
                        cocktail will awaken the <br /> rhythm of your Latin Soul.
                      </p>
                      <Link to="/the-serves">
                        <Button className="learn-more-btn mt-0 serveBtn" variant="danger">
                          {" "}
                          SEE MORE SERVES
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
          <section className="fourth-conitainer">
            <div className="blendModeDiv">
              <ResponsiveHome />
            </div>
          </section>
        </div>
        </motion.div>
      </HelmetProvider>
    </>
  );
}