import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
export default function NotFound(props) {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>404 Page Not Found!</title>
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
        >
          <section className="error-page">
            <Container style={{ maxWidth: "740px" }}>
              <div>
                <h1 className="text-center">
                  <span>404</span> ERROR
                </h1>
                <h2>THIS IS NOT THE PAGE YOU’RE LOOKING FOR</h2>
                <p>
                  Sorry, it appears the page you were looking for doesn’t exist
                  anymore or may have moved. Go back to our{" "}
                  <Link to="/">Home Page</Link> to explore more.
                </p>
              </div>
            </Container>
          </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}
