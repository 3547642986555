import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import { Localhost_Domain } from "../../constant";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";



export default function TheStory() {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>BEGINS WITH THE LEGEND OF THOMAS PARR</title>
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
        >
        <section className="story-container pb-5">
          <Container>
            <Row>
              <Col sx={12}>
                <h2 className="h2-heading text-center fontLarge">
                  <span className="colorRed">O</span>ur Story
                </h2>
                <h3 className="h2-heading pt-0">
                  <span className="colorRed">B</span>EGINS WITH THE <br /> LEGEND OF THOMAS PARR
                </h3>
                <h3 className="h3-heading-hr"></h3>
              </Col>
            </Row>

            <div className="dFlex firstFlex">
              <div className='mt120 firstChild'>
                <Image
                  src={`${Localhost_Domain}/img/storyImageOne.png`}
                  alt="dsada"
                  className="story-image1 img-fluid m-auto"
                />
              </div>
              <div className='mt120 secondChild'>
                <p className="storyContainer1">
                  <span className="colorRed">T</span>homas Parr a.k.a. Old Tom Parr was an English
                  farmer born in 1483. He reached the extraordinary age of 152
                  years and 9 months, lived through the reign of ten monarchs and
                  witnessed the Golden Age of Queen Elizabeth I.
                </p>
                <Image
                  src={`${Localhost_Domain}/img/storyImageOne.png`}
                  alt="dsada"
                  className="story-image1 img-fluid m-auto d-none"
                />
                <p className="storyContainer1">
                  <span className="colorRed">D</span>uring his long life he became a revered national
                  figure and a legendary symbol of wisdom and maturity.
                </p>
                <p className="storyContainer1">
                  <span className="colorRed">I</span>n tribute to his great prestige, King Charles I
                  proclaimed by special decree that the man who had lived longer
                  than all others should be laid to rest alongside the great and
                  worthy in Westminster Abbey.
                </p>
              </div>
            </div>

            <Row>
              <Col sx={12}>
                <h2 className="h2-heading text-center">
                  <span className="colorRed">T</span>he Greenlees brothers
                </h2>
                <h3 className="h3-heading-hr"></h3>
              </Col>
            </Row>

            <div className="secondFlex dFlex">
              <div className='mt120 firstChild'>
                <p className="storyContainer2">
                  <span className="colorRed">I</span>n 1871, Scottish brothers James and Samuel
                  Greenlees established their whisky blender and merchant business
                  at Gresham Buildings in the city of London. The market for
                  blended Scotch whiskies was still in its infancy – and they
                  hoped to exploit this newly emerging interest.
                </p>
              </div>
              <div md={6} className='mt120 text-center secondChild'>
                <Image
                  src={`${Localhost_Domain}/img/storyImage2.png`}
                  alt="dsada"
                  className="story-image2 img-fluid"
                />
              </div>
              <div md={6} className='mt70 thirdChild'>
                <Image
                  src={`${Localhost_Domain}/img/section2DesktopImage3.jpg`}
                  alt="dsada"
                  className="story-image3 img-fluid"
                />
                <p className="storyContainer1 fs25 d-none mt70">
                  <span className="colorRed">T</span>he Greenlees brothers believed that combining the
                  best whiskies at precisely the right point in their maturation
                  process could produce a blend of extraordinary flavor.
                </p>
                <Image
                    src={`${Localhost_Domain}/img/section2DesktopImage4.jpg`}
                    alt="dsada"
                    className="story-image4 "
                />
              </div>
              <div md={6} className='mt70 forthChild'>
                <p className="storyContainer1 fs25">
                  <span className="colorRed">T</span>he Greenlees brothers believed that combining the
                  best whiskies at precisely the right point in their maturation
                  process could produce a blend of extraordinary flavor.
                </p>
              </div>
              <div className='mt-4 fifthChild'>
                <div className="drivenDiv w-100 text-center">
                  {/* <Image
                    src={`${Localhost_Domain}/img/section2DesktopImage4.jpg`}
                    alt="dsada"
                    className="story-image4 img-fluid forMobile ms-auto"
                  /> */}
                  <p className="hKVxqh">
                    <span className="colorRed">D</span>riven by this conviction, they experimented
                    tirelessly. They were determined to create a blend that would be
                    acknowledged as one of the finest Scotch whiskies the world had
                    ever seen.{" "}
                  </p>
                  <p className="gbvIR">
                    James and Samuel became two of the most skilled and successful
                    pioneers of the blended whisky business of their time.
                  </p>
                </div>
              </div>
            </div>

            <Row>
              <Col>
                <h2 className="h2-heading text-center">
                  <span className="colorRed">A</span>ncient old parr
                </h2>
                <h3 className="h3-heading-hr"></h3>
              </Col>
            </Row>
                
              <div className="dFlex">
                <div className='mt70 pt-3 d-flex align-items-center firstChild'>
                  <div>
                    <p className="storyContainer2">
                      <span className="colorRed">I</span>n 1909, the Greenlees Brothers introduced (Ancient) Old Parr.
                    </p>
                    <p className="storyContainer2">
                      They named their blend after the legendary figure of Thomas
                      Parr, said to be England’s longest-lived man, aged 152 when he
                      died in 1635. His long life symbolized the maturity of the
                      whiskies used in the blend, whilst his wisdom represented the
                      skill and knowledge required to produce it.
                    </p>
                  </div>
                </div>
                <div className='mt70 secondChild'>
                  <Image
                    src={`${Localhost_Domain}/img/storyImage5Desktop.png`}
                    alt="img not found"
                    className="story-image5 img-fluid"
                  />
                </div>
              </div>
              
            <Row>
              <Col>
                <h2 className="h2-heading text-center hiddenText">
                  <span className="colorRed">O</span>LD PARR EXPANDS TO THE REST OF THE WORLD
                </h2>
                <h3 className="heading_middle_text">JAPAN</h3>
              </Col>
              <div>
                <p className="storyContainer1 p-0 fs25">
                  <span className="colorRed">I</span>n 1871, as international trade began to develop
                  between East and West, Emperor Meji of Japan dispatched his
                  foreign minister, Iwakura Tomomi, on a two-year diplomatic tour
                  of America and Europe, known as the Iwakura mission.
                </p>
                <p className="storyContainer1 p-0 fs25">
                  Although unsuccessful on the tour’s primary mission, legend has
                  it that he returned to Japan with a case of his favorite Scotch
                  whisky: a Greenlees Brothers blend.
                </p>
              </div>
              <div>
                <h3 className="heading_middle_text">LATIN AMERICA</h3>
              </div>

              <div>
                <p className="storyContainer1 p-0 fs25 text-dark">
                  <span className="colorRed">O</span>ld Parr has had historically strong presence in
                  Latin America, with its first shipments to the region estimated
                  to have taken place in 1921.
                </p>
                <p className="storyContainer1 p-0 fs25 text-dark">
                  Today, Latin America and the Caribbean account for the vast
                  majority of Old Parr global sales.
                </p>
              </div>

              <div className="srtoryrow6">
                <div className="golden-line"></div>
                <div className="text-center">
                  <h2 className="h2span-text pt-4">
                    <span className="red-letter">I</span>n 1941
                  </h2>
                  <div className="srtoryrow6text drivenDiv">
                   <div className="d-flex justify-content-center">
                    <Image
                        src={`${Localhost_Domain}/img/Old-Parr-1950s-Bottle Front_CROP.png`}
                        alt=""
                        className="img-fluid"
                      />
                    <Image
                        src={`${Localhost_Domain}/img/Old Parr-1950s-Bottle-Back_CROP.png`}
                        alt=""
                        className="img-fluid"
                      />
                   </div>
                    <p>the label was altered with the word ‘Grand’ replacing ‘Ancient’
                      and a repositioning of the Thomas Parr legend.</p>
                  </div>
                </div>
              </div>
              <div className='pt-3 d-flex align-items-center'>
                <div className='drivenDiv'>
                  <div className="golden-line"></div>
                  <h2 className="h2span-text pt-4">
                    <span className="red-letter">I</span>n 1979
                  </h2>
                </div>
              </div>
              <div className='pt-3 d-flex align-items-center'>
                <div className='drivenDiv'>
                  <Image src={`${Localhost_Domain}/img/storyLogo.png`} className='img-fluid' />
                  <p className="p-text-center fs30">
                    MacDonald Greenlees Ltd. is awarded the Queen’s Award for Export
                    Achievement.
                  </p>
                </div>
              </div>
              <div className='pt-3 d-flex align-items-center'>
                <div className='drivenDiv'>
                  <div className="golden-line"></div>
                  <h2 className="h2span-text pt-4">
                    <span className="red-letter">T</span>oday
                  </h2>
                  <Image src={`${Localhost_Domain}/img/bottle-hd.png`} className='img-fluid oldparrBotel' />
                  <p className="p-text-center fs30">
                    Old Parr is enjoyed around the world by a bold generation that
                    preaches authenticity, outdoors and fun times.
                  </p>
                </div>
              </div>
            </Row>
          </Container>
        </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}