import React from "react";
import Navigation from "./Menu";
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../../../node_modules/bootstrap/dist/css/bootstrap.css';
import { Row } from "reactstrap";
  const Header = () => {
  return (
    <>
    <header className="header-wrapper headerwrapper">
        <div className="container-fluid">
            <div className="kbifrL">
                <Row className="kdkOCp">
                    <Navigation />
                </Row>       
            </div>            
        </div>        
    </header>    
    </>
  );
};

export default React.memo(Header);  