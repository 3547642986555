import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Iframe from "react-iframe";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { motion } from "framer-motion";

export default function FindNearMe() {
  const [iframeUrl, setiFrameUrl] = useState("");

  const getParameterByName = (name) => {
    var match = RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search)
    return match && decodeURIComponent(match[1].replace(/\+/g, " "))
  }

  const findNearMe = () => {
    let BaseiFrameUrl = 'https://where-to-buy.co/widgets/core/BuyOnlineBuyLocalV2/index.html?pid=12042881&type=local';
    let Latitude = getParameterByName("lat")
    let Langitude = getParameterByName("lng")
    if (Latitude && Langitude) {
      setiFrameUrl(BaseiFrameUrl + "&lat=" + Latitude + "&long=" + Langitude);
    } else {
      setiFrameUrl(BaseiFrameUrl);
    }
  }

  useEffect(() => {
    findNearMe();
  }, [iframeUrl]);

  // Find Near Me
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Find Near Me</title>
        </Helmet>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.6,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          exit={{ opacity: 0 }}
        >
        <section className="content page-where-to-buy">
          <div className="container-fluid">
            <Row>
              <Col sm={12}>
                <div className="where-to-buy-Headline text-center">
                  <h1>FIND NEAR ME</h1>
                </div>
              </Col>
            </Row>
            <Container>
              <Row>
                <Col xs={12} className="find-section text-center">
                  <Iframe
                    url={iframeUrl}
                    width="100%"
                    height="800px"
                    id="wtbIframe"
                    className="wtbIframe"
                    overflow="hidden"
                    minHeight="20px"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        </motion.div>
      </HelmetProvider>
    </>
  );
}
