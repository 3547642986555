import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { createRef } from 'react';
import "./App.css";
import './responsive.css'
import Header from "./components/includes/Header";
import Footer from "./components/includes/Footer";
import Home from "./components/Home";
import TheServe from "./components/Pages/the-serves";
import TheStory from "./components/Pages/the-story";
import WheretoBuy from "./components/Pages/where-to-buy";
import OurWhiskies12 from "./components/Pages/old-parr-12";
import OurWhiskies18 from "./components/Pages/old-parr-18";
import Newsletter from "./components/Pages/newsletter";
import ShopOnline from "./components/Pages/shop-online";
import FindNearMe from "./components/Pages/find-near-me";
import TheServeChildComponent from "./components/Pages/serve-child";
import { AnimatePresence } from "framer-motion";
import NotFound from "./components/Pages/404";
import ContactUs from "./components/Pages/ContactUs";
import ThankYou from "./components/Pages/ThankYou";
function App() {
  const location = useLocation();
  return (
    <>
      <div className="relative pb-10 min-h-screen">
        <Header />
        <main id="main">
          <AnimatePresence>
          <Routes location={location} key={location.pathname} >
            <Route path='*' element={<NotFound />} />
            <Route path={`/the-serves/*`} element={<NotFound/>} />
            <Route path="/" element={<Home /> } nodeRef={createRef()}/>
            <Route path="/whiskies/old-parr-12-yo" element={<OurWhiskies12 />} nodeRef={createRef()} />
            <Route path="/the-whisky/" element={<OurWhiskies12 />} nodeRef={createRef()} />
            <Route path="/whiskies/old-parr-18-yo" element={<OurWhiskies18 />} nodeRef={createRef()}/>
            <Route path="/newsletter" element={<Newsletter />} nodeRef={createRef()}/>
            <Route path="/the-serves" element={<TheServe />} nodeRef={createRef()} />
            <Route path="/the-story" element={<TheStory />} nodeRef={createRef()}/>
            <Route path="/where-to-buy" element={<WheretoBuy />} nodeRef={createRef()} />
            <Route path="/where-to-buy/shop-online" element={<ShopOnline />} nodeRef={createRef()}/>
            <Route path="/where-to-buy/find-near-me" element={<FindNearMe />} nodeRef={createRef()} />
            <Route path={`/the-serves/:slug`} element={<TheServeChildComponent />} nodeRef={createRef()} />
            <Route path={`/contact-us`} element={<ContactUs />} nodeRef={createRef()} />
            <Route path={`/thank-you`} element={<ThankYou />} nodeRef={createRef()} />
            {/* <Route path="/page-slider" element={<Slide />} /> */}
          </Routes>
          </AnimatePresence>
        </main>
        <Footer />
      </div>
    </>

  );
}

export default App;
