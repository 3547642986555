import React from "react";
import Slider from "react-slick";
import { Localhost_Domain } from "../../constant";
import Image from "react-bootstrap/Image";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Responsive = (props) => {
    const data = Object.values(props);
    // const history = useHistory();
    const navigate = useNavigate();
    const goNavigate = (url) => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
        navigate(`../the-serves/${url}`, { replace: true })
    }


    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "red" }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: "block", background: "green" }}
                onClick={onClick}
            />
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToShow: 3,
        slidesToScroll: 1,
        mobileFirst: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 950,
                settings: "unslick",
            },
            // {
            //     breakpoint: 768,
            //     settings: "unslick",
            // },
            // {
            //     breakpoint: 575,
            //     settings: "unslick",
            // },
            // {
            //     breakpoint: 425,
            //     settings: "unslick",
            // },
            // {
            //     breakpoint: 375,
            //     settings: "unslick",
            // },
            // {
            //     breakpoint: 320,
            //     settings: "unslick",
            // }
        ]
    };
    return (
        <div>
            <Slider {...settings}>
                {data.map((elm, index) => (
                    <Row key={index}>
                        <div onClick={() => goNavigate(`${elm.slug}`)} className='margin50' >
                            <Row><Image src={`${Localhost_Domain}/img/Slider/${elm.img_name}`} className='img-fluid' /></Row>
                            <Row><h4>{elm.title}</h4></Row>
                        </div>
                    </Row>
                ))}
            </Slider>
        </div>
    );
}


export default Responsive;